<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from 'axios';
import Swal from "sweetalert2";


/**
 * Elements component
 */
export default {
  page: {
    title: "Pengaturan Pengguna",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Pengaturan Pengguna",
      items: [
      ],
      checked: true,
      smchecked: true,
      lgchecked: true,
      selectedToogle: "A",
      selectedDefault: "a",
      rightcheck: "accepted",
      status: "accepted",
      userId: null,
      userName: null,
      hasData: false,
      settingId: '',
      data: {
        printAddress: '',
        user: ''
      }
    };
  },
  async mounted(){
    const loggeduser = localStorage.getItem('user');
    const jsonUser = JSON.parse(loggeduser)
    this.userName = jsonUser.username
    this.userId = await axios.get(process.env.VUE_APP_URL_FEATHERS + 'login?username=' + jsonUser.username)
            .then(response => {
              return response.data.data[0]._id
          })
    await axios.get(process.env.VUE_APP_URL_FEATHERS + `user-setting/?$populate=user&user=${this.userId}`)
            .then(response => {
              if (response.data.total != 0){
                this.settingId = response.data.data[0]._id
                this.hasData = true
                this.data = response.data.data[0]
              }
          })
  },
  methods: {
    async submit () {
      let loader = this.$loading.show({
                    // Optional parameters
                    container: null,
                    canCancel: false,
                    color: '#1976D2'
                });
      if(this.hasData){
        console.log('data patch', this.data)
        await axios.patch(process.env.VUE_APP_URL_FEATHERS + 'user-setting/' + this.settingId, this.data)
          .then( response => {
            loader.hide()
            Swal.fire("Sukses!", "Data berhasil diperbaharui", "success");
            console.log(response)
        }).catch(error => {
          loader.hide()
          Swal.fire("Gagal!", "Data gagal diperbaharui, "+ error.message, "error");
          console.log('error', error)
        })
      } else {
        this.data.user = this.userId
        console.log('data post', this.data)
        await axios.post(process.env.VUE_APP_URL_FEATHERS + 'user-setting', this.data)
          .then( response => {
            loader.hide()
            Swal.fire("Sukses!", "Data berhasil disimpan", "success");
            console.log(response)
        }).catch(error => {
          loader.hide()
          Swal.fire("Gagal!", "Data gagal diperbaharui, "+ error.message, "error");
          console.log('error', error)
        })
      }
    }
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="card-title">Textual inputs</h4>
            <p class="card-title-desc">
              Here are examples of
              <code>.form-control</code> applied to each textual HTML5
              <code>&lt;input&gt;</code>
              <code>type</code>.
            </p> -->

            <div class="row">
              <div class="col-6">
                <form @submit.prevent="submit" class="form-horizontal" role="form">
                  <b-form-group
                    class="mb-3"
                    id="example text"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    label="Alamat Printer"
                    label-for="text"
                  >
                    <b-form-input
                      for="text"
                      v-model="data.printAddress"
                    ></b-form-input>
                  </b-form-group>
                  <div class="mt-4">
                    <b-button type="submit" variant="primary"
                      >Simpan</b-button
                    >
                  </div>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

  </Layout>
</template>
